import React from "react";
import { Link } from "gatsby";
import Offcanvas from "react-bootstrap/Offcanvas";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import classNames from "clsx";

import { FixMe } from "../blocks/FixMe";
import { MegaMenu, MegaMenuItem } from "./MegaMenu";
import type { MegaMenuItemProps } from "./MegaMenu";

import CengageWorkLogo from "../../images/svg-inline/logos/CengageWork-Primary-Color-resave.svg";

import IconCreate from "../../images/svg-inline/icons/corporate/CW_Create.svg";
import IconUpskill from "../../images/svg-inline/icons/corporate/CW_Upskill.svg";
import IconAccess from "../../images/svg-inline/icons/corporate/CW_Access.svg";
import IconHealthcare from "../../images/svg-inline/icons/corporate/CW_Healthcare.svg";
import IconCybersecurity from "../../images/svg-inline/icons/corporate/CW_Cybersecurity.svg";
import IconAdvancedManufacturing from "../../images/svg-inline/icons/corporate/CW_AdvancedManufacturing.svg";
import IconSkilledTrades from "../../images/svg-inline/icons/corporate/CW_SkilledTrades.svg";

import * as styles from "./PrimaryNav.module.scss";

interface PrimaryNavItemProps extends MegaMenuItemProps {
  title: string;
  icon: React.ReactNode;
  iconSize?: number;
  iconStyle?: React.CSSProperties;
}

interface PrimaryNavProps {
  children: React.ReactNode;
}

const iconSize = 96;

const PrimaryNavItem: React.FC<PrimaryNavItemProps> = ({
  icon,
  title,
  children,
  iconSize,
  iconStyle,
  className,
  ...rest
}) => {
  return (
    <MegaMenuItem
      {...rest}
      className={classNames(styles["PrimaryNavItem"], className)}
    >
      <div
        className={classNames(
          "flex-row flex-md-column my-2 my-md-0",
          styles["PrimaryNavItemWrapper"]
        )}
      >
        <div
          className={classNames(
            "mega-menu-item-icon",
            styles["PrimaryNavIconWrapper"]
          )}
          // style={{ width: iconSize, ...iconStyle }}
          style={
            {
              // "--primary-nav-icon-size": `${iconSize}px`,
              width: iconSize,
              ...iconStyle,
            } as React.CSSProperties
          }
        >
          {icon}
        </div>
        <div className="d-flex flex-column flex-fill">
          <div
            className={classNames(
              "lh-12",
              "mega-menu-item-title",
              styles["PrimaryNavTitle"]
            )}
          >
            {title}
          </div>
          <p className="text-wrap text-start font-body mega-menu-item-desc my-0">
            {children}
          </p>
        </div>
      </div>
    </MegaMenuItem>
  );
};

const PrimaryNav: React.FC<PrimaryNavProps> = ({ children }) => {
  return (
    <Navbar.Offcanvas
      id="basic-navbar-nav"
      placement="end"
      scroll={false}
      style={{
        "--bs-offcanvas-width": "max(75vw, 350px)",
        "--bs-backdrop-opacity": 1,
        "--bs-backdrop-bg": "rgba(var(--bs-darker-rgb), 0.6)",
        "--bs-offcanvas-padding-x": 0,
        "--bs-offcanvas-padding-y": 0,
      }}
    >
      <Offcanvas.Header closeButton className="p-2 my-1 me-2">
        <Offcanvas.Title id={`main-nav`}>
          <Link to="/">
            <CengageWorkLogo
              width={120}
              height={40}
              alt={"Cengage Work"}
              className={classNames("mx-2 my-1")}
            />
          </Link>
          <span className="visually-hidden">Menu</span>
        </Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body
        className={classNames("fs-4 font-title user-select-none text-bg-light")}
      >
        <Nav className="ms-auto align-items-stretch justify-content-stretch">
          <Nav.Link
            as={Link}
            to="/"
            className="nonmegamenu-item nav-home d-md-none"
          >
            Home
          </Nav.Link>

          <MegaMenu title="Solutions" className="megamenu-solutions">
            <PrimaryNavItem
              href="/create_new_talent"
              title="Create New Talent"
              iconSize={iconSize}
              icon={<IconCreate width={iconSize} height={iconSize} />}
              iconStyle={
                {
                  "--primary-nav-icon-color": "rgb(64, 125, 113)", // tealLight is too light
                  transform: "scale(1.35)",
                } as React.CSSProperties
              }
            >
              Identify and train new talent through dedicated train-to-hire
              programs
            </PrimaryNavItem>
            <PrimaryNavItem
              href="/upskill_existing_talent"
              title="Upskill Existing Talent"
              iconSize={iconSize}
              icon={<IconUpskill width={iconSize} height={iconSize} />}
              iconStyle={
                {
                  "--primary-nav-icon-color": "var(--bs-teal)",
                  transform: "scale(1.35)",
                } as React.CSSProperties
              }
            >
              Train current employees or students to earn certifications and
              professional skills
            </PrimaryNavItem>
            <PrimaryNavItem
              href="/access_qualified_talent"
              title="Access Qualified Talent"
              iconSize={iconSize}
              icon={<IconAccess width={iconSize} height={iconSize} />}
              iconStyle={
                {
                  "--primary-nav-icon-color": "var(--bs-tealDark)",
                  transform: "scale(1.35)",
                } as React.CSSProperties
              }
            >
              Select qualified students from local workforce and academic
              partners
            </PrimaryNavItem>
          </MegaMenu>

          <MegaMenu title="Industries" className="megamenu-industry">
            <PrimaryNavItem
              href="/healthcare"
              iconSize={iconSize}
              icon={<IconHealthcare width={iconSize} height={iconSize} />}
              title="Healthcare"
            >
              Delivering quality patient care requires high-quality training and
              pre-vetted certified talent
            </PrimaryNavItem>
            <PrimaryNavItem
              href="/it_cybersecurity"
              iconSize={iconSize}
              icon={<IconCybersecurity width={iconSize} height={iconSize} />}
              title="IT / Cybersecurity"
            >
              As the IT and cybersecurity landscape evolves, employers need
              best-in-class training and verified talent
            </PrimaryNavItem>
            <PrimaryNavItem
              href="/skilled_trades"
              iconSize={iconSize}
              icon={<IconSkilledTrades width={iconSize} height={iconSize} />}
              title="Skilled Trades"
            >
              From electricians and plumbers to HVAC technicians, skilled trades
              are in demand across our workforce
            </PrimaryNavItem>
            <PrimaryNavItem
              href="/advanced_manufacturing"
              iconSize={iconSize}
              icon={
                <IconAdvancedManufacturing width={iconSize} height={iconSize} />
              }
              title="Advanced Manufacturing"
            >
              These high-tech jobs of the future require continual reskilling
              and upskilling to meet future demand
            </PrimaryNavItem>
          </MegaMenu>

          <Nav.Link
            as={Link}
            to="/about_us"
            className="nonmegamenu-item nav-about"
          >
            About Us
          </Nav.Link>

          {children}
        </Nav>
      </Offcanvas.Body>
    </Navbar.Offcanvas>
  );
};

export { PrimaryNav };
