import React from "react";
import { Link } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import classNames from "clsx";

import type { StyledComponentProps } from "./types";

// import CengageWorkWhiteLogo from "../images/svg-inline/logos/cengage-work-logo-r7-currentColor.svg";
import CengageWorkWhiteLogo from "../images/svg-inline/logos/CengageWork-Primary-currentColor.svg";

const Footer: React.FC<StyledComponentProps> = ({ className, style }) => {
  return (
    <footer
      className={classNames("py-5 text-bg-dark", className)}
      style={style}
    >
      <Container fluid={"xl"} className="px-4 gx-5">
        <Row>
          <Col sm={6} lg={4} className="mb-3">
            <div>
              <CengageWorkWhiteLogo
                width={104}
                height={38}
                alt={"Cengage Work"}
                className={classNames("mt-1 mb-5 mx-0")}
              />
            </div>
            <p>
              Cengage Work creates net new skilled talent in critical fields by
              providing flexible, affordable online courses and programs that
              deliver outcomes for businesses and for students. We connect
              employers with qualified, certified students who are ready to join
              their organizations. Cengage Work is a part of Cengage Group, a
              global edtech company with more than 100 years of experience
              serving learners.
            </p>
          </Col>

          <Col sm={6} lg={2} className="mb-3 vstack gap-2 offset-lg-1">
            <div>
              <div className={"fw-semibold mb-2"}>Cengage Work</div>
              <ul className="nav flex-column mb-2">
                <li className="nav-item">
                  <Link to="/" className="nav-link px-0" rel="index">
                    Home
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about_us" className="nav-link px-0">
                    About Us
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/about_us/#contact" className="nav-link px-0">
                    Contact Us
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="https://www.cengagegroup.com/news/Cengage+Work/"
                    rel="external"
                    className="nav-link px-0"
                  >
                    News
                  </a>
                </li>
              </ul>
            </div>

            <div>
              <div className={"fw-semibold mb-2"}>Solutions</div>
              <ul className="nav flex-column mb-2">
                <li className="nav-item">
                  <Link to="/create_new_talent" className="nav-link px-0">
                    Create New Talent
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/upskill_existing_talent" className="nav-link px-0">
                    Upskill Existing Talent
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/access_qualified_talent" className="nav-link px-0">
                    Access Qualified Talent
                  </Link>
                </li>
              </ul>
            </div>
          </Col>

          <Col sm={6} lg={2} className="mb-3 vstack gap-2">
            {/* Phase III */}
            <div>
              <div className={"fw-semibold mb-2"}>Industries</div>
              <ul className="nav flex-column mb-2">
                <li className="nav-item">
                  <Link to="/healthcare" className="nav-link px-0">
                    Healthcare
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/it_cybersecurity" className="nav-link px-0">
                    IT / Cybersecurity
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/advanced_manufacturing" className="nav-link px-0">
                    Advanced Manufacturing
                  </Link>
                </li>
                <li className="nav-item">
                  <Link to="/skilled_trades" className="nav-link px-0">
                    Skilled Trades
                  </Link>
                </li>
              </ul>
            </div>
            <div>
              <div className={"fw-semibold mb-2"}>Brands</div>
              <ul className="nav flex-column mb-2">
                <li className="nav-item">
                  <Link to="/about_us/#contact" className="nav-link px-0">
                    Ready to Hire
                  </Link>
                </li>
                <li className="nav-item">
                  <a
                    href="https://www.ed2go.com"
                    className="nav-link px-0"
                    rel="external"
                  >
                    ed2go
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    href="https://www.infosecinstitute.com/"
                    className="nav-link px-0"
                    rel="external"
                  >
                    Infosec
                  </a>
                </li>
              </ul>
            </div>
          </Col>

          <Col sm={6} lg={3} className="mb-3">
            <div className={"fw-semibold mb-2"}>Cengage</div>
            <ul className="nav flex-column mb-2">
              <li className="nav-item">
                <a
                  href="https://www.cengagegroup.com/"
                  className="nav-link px-0"
                  rel="external"
                >
                  Cengage Group
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.cengage.com/legal/#copyright"
                  className="nav-link px-0"
                  rel="external"
                >
                  Copyright Notices
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.cengage.com/legal/#terms"
                  className="nav-link px-0"
                  rel="external"
                >
                  Terms of Use
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.cengage.com/privacy"
                  className="nav-link px-0"
                  rel="external"
                >
                  Privacy Statement
                </a>
              </li>
              <li className="nav-item">
                <a
                  href="https://www.cengage.com/accessibility/"
                  className="nav-link px-0"
                  rel="external"
                >
                  Accessibility
                </a>
              </li>
            </ul>{" "}
            <p className={"small opacity-75 my-4"}>
              Copyright © Cengage Work. All rights reserved. The material on
              this site cannot be reproduced or redistributed unless you have
              obtained prior written permission from Cengage Work. Cengage Work
              is a registered trademark and a part of Cengage Learning.
            </p>
          </Col>
        </Row>
      </Container>
    </footer>
  );
};

export { Footer };
