import React from "react";
import SSRProvider from "react-bootstrap/SSRProvider";
import ThemeProvider from "react-bootstrap/ThemeProvider";
import classNames from "clsx";

import { Header } from "./header/Header";
import { Footer } from "./Footer";
import type { StyledComponentProps } from "./types";

import "../styles/styles.scss";
import * as styles from "../styles/CengageWork.module.scss";
import { BuildInfo } from "./BuildInfo";

interface SiteLayoutProps extends StyledComponentProps {
  children?: React.ReactNode;
}

const SiteLayout: React.FC<SiteLayoutProps> = ({ children }) => {
  return (
    <SSRProvider>
      <ThemeProvider>
        <Header
          // NOTE: Requires settings in styles.scss: #gatsby-focus-wrapper
          className={classNames(
            "sticky-top user-select-none",
            "fs-4 font-title"
          )}
        >
          <BuildInfo />
        </Header>
        <div
          className={classNames(
            styles["PagePanelContainer"],
            "smooth-scroll",
            "d-flex flex-column flex-fill"
          )}
        >
          <main
            className={classNames(
              "d-flex flex-column flex-fill",
              "position-relative"
            )}
          >
            {children}
          </main>
          <Footer />
        </div>
      </ThemeProvider>
    </SSRProvider>
  );
};

export default SiteLayout;
export type { SiteLayoutProps };
