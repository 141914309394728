import React, { useState, useContext, useEffect, useCallback } from "react";
import { Link } from "gatsby";
import { globalHistory } from "@reach/router";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import classNames from "clsx";

import { useScrollPosition } from "../../hooks/useScrollPosition";
import { PrimaryNav } from "./PrimaryNav";
import type { StyledComponentProps } from "../types";
import type { HandlerProps } from "../../hooks/useScrollPosition";

import CengageWorkLogo from "../../images/svg-inline/logos/CengageWork-Primary-Color-resave.svg";

interface HeaderProps extends StyledComponentProps {
  children?: React.ReactNode;
}

const Header: React.FC<HeaderProps> = ({
  className = "py-2",
  style,
  children,
}) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const handleResize = useCallback(() => {
    setIsOpen(false);
  }, [setIsOpen]);

  // Listen to the router state; if the route changes, close navigation
  useEffect(() => {
    return globalHistory.listen(({ action }) => {
      if (action === "PUSH") handleResize();
    });
  }, [setIsOpen]);

  // Listen for window resize; close mobile navigation if open
  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setIsOpen]);

  useScrollPosition({
    debounceMs: 250,
    handler: (props: HandlerProps) => {
      setIsScrolled(props.current["y"] > 3);
    },
  });

  return (
    <Navbar
      as={"header"}
      bg="light"
      expand={"md"}
      expanded={isOpen}
      onToggle={(expanded: boolean) => setIsOpen(expanded)}
      className={classNames("py-2", isScrolled && "shadow", className)}
      style={{
        transition: `all 400ms ease-in`,
        ...style,
      }}
    >
      <Container fluid={"xl"} className={"px-2"}>
        <Navbar.Brand as={Link} to="/">
          <CengageWorkLogo
            width={120}
            height={40}
            alt={"Cengage Work"}
            className={classNames("mx-2 my-1")}
          />
          <span className="visually-hidden">Home</span>
        </Navbar.Brand>

        <Navbar.Toggle aria-controls="main-nav" className="border-0" />

        <PrimaryNav>{children}</PrimaryNav>
      </Container>
    </Navbar>
  );
};

export { Header };
